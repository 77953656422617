import React, { Component } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { DOMAIN_MAIN } from "../../../../constants/requestUrl";
import { apiRequestGet, apiRequestPost } from "../../../../utils/requestHelper";
import PaginationBt4 from "../../../Shared/PaginationBt4";
import moment from "moment";
import "./Payment.css";

export default class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classList: [],
      studentList: [],
      stadiumInfo: "",
      stadiumModalInfo: "",
      totalPage: 0,
      currentPage: this.props.match.params.page
        ? Number(this.props.match.params.page)
        : 1,
      name: "",
    };
    this.id = "";
  }

  getClassList() {
    let urlRequest = `${DOMAIN_MAIN}/classes/info`;
    apiRequestGet(urlRequest).then((result) => {
      if (result.error)
        NotificationManager.error("Không thể lấy dữ liệu", "Lỗi", 2000);
      else {
        this.setState({ classList: result });
        let checkClass = result.find((cl) => {
          return this.props.match.params.classId === cl._id;
        });
        if (checkClass) {
          this.refs["isAdvanced"].value = checkClass.isAdvanced
            ? "Lớp nâng cao"
            : "Lớp thường";
        } else {
          this.refs["isAdvanced"].value = "";
        }
      }
    });
  }

  getStadiumInfo(classId, isModal) {
    if (classId !== "all") {
      let urlRequest = `${DOMAIN_MAIN}/students/stadium/info/${classId}`;
      apiRequestGet(urlRequest).then((result) => {
        if (result.error)
          NotificationManager.error("Không thể lấy dữ liệu", "Lỗi", 2000);
        else
          isModal
            ? this.setState({ stadiumModalInfo: result.address })
            : this.setState({ stadiumInfo: result.address });
      });
    } else {
      isModal
        ? this.setState({ stadiumModalInfo: "" })
        : this.setState({ stadiumInfo: "" });
    }
  }

  getStudentsList(classId, pageNumber) {
    const { props } = this;
    const page = pageNumber
      ? pageNumber
      : props.match.params.page
      ? props.match.params.page
      : 1;
    this.setState({ currentPage: page });
    let urlRequest = `${DOMAIN_MAIN}/students/payment/page/${page}?name=${this.state.name}`;
    try {
      if (classId && classId !== "all") {
        urlRequest = `${DOMAIN_MAIN}/students/payment/class/${classId}/page/${page}?name=${this.state.name}`;
      } else if (props.match.params.classId && classId !== "all") {
        urlRequest = `${DOMAIN_MAIN}/students/payment/class/${props.match.params.classId}/page/${page}?name=${this.state.name}`;
      }
      apiRequestGet(urlRequest).then((result) => {
        if (result.error)
          NotificationManager.error("Không thể lấy dữ liệu", "Lỗi", 2000);
        else {
          this.setState({
            studentList: result.resultPayment,
            totalPage: result.total,
          });
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  onChangeSelectStadium(e) {
    const { state } = this;
    this.setState({
      totalPage: 0,
    });
    let checkClass = state.classList.find((cl) => {
      return e.target.value === cl._id;
    });
    if (checkClass) {
      this.refs["isAdvanced"].value = checkClass.isAdvanced
        ? "Lớp nâng cao"
        : "Lớp thường";
    } else {
      this.refs["isAdvanced"].value = "";
    }
    if (this.props.match.params.page) {
      window.location.href = `/admin/hoc-phi/${e.target.value}/${this.props.match.params.page}`;
    } else {
      window.location.href = `/admin/hoc-phi/${e.target.value}`;
    }
  }

  handlePageChange(pageNumber) {
    window.location.href = `/admin/hoc-phi/${this.props.match.params.classId}/${pageNumber}`;
    this.getStudentsList(this.props.match.params.classId, pageNumber);
    this.setState({
      currentPage: pageNumber,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.classId !== nextProps.match.params.classId) {
      this.getStadiumInfo(nextProps.match.params.classId);
      this.getStudentsList(nextProps.match.params.classId);
    }
  }

  componentDidMount() {
    this.getClassList();
    this.getStudentsList(this.props.match.params.classId);
    this.getStadiumInfo(this.props.match.params.classId);
  }

  rollCallStudent(id) {
    return apiRequestPost(
      `${DOMAIN_MAIN}/students/rollcall`,
      JSON.stringify({ studentId: id })
    ).then((result) => {
      if (result && result.error)
        NotificationManager.error("Điểm danh không thành công", "Lỗi", 2000);
      else {
        this.getStudentsList(
          this.props.match.params.classId,
          this.props.match.params.page
        );
      }
    });
  }

  removeRollCallStudent(id) {
    return apiRequestPost(
      `${DOMAIN_MAIN}/students/rollcall/remove`,
      JSON.stringify({ studentId: id })
    ).then((result) => {
      if (result && result.error)
        NotificationManager.error(
          "Hủy điểm danh không thành công",
          "Lỗi",
          2000
        );
      else {
        this.getStudentsList(
          this.props.match.params.classId,
          this.props.match.params.page
        );
        NotificationManager.success(
          "Hủy điểm danh thành công",
          "Thành công",
          2000
        );
      }
    });
  }

  render() {
    const { state, props } = this;
    const id = props.match.params.classId;
    return (
      <div className="dashboard-wrapper">
        <NotificationContainer />
        <div className="container-fluid  dashboard-content">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="page-header" id="top">
                <div className="page-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/admin/" className="breadcrumb-link">
                          Trang chủ
                        </a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Quản lý học phí - doanh thu
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="card">
                <h5 className="card-header">Quản lý học phí</h5>
                <div className="card-body border-top">
                  <div className="form-group">
                    <label htmlFor="input-select">Danh sách lớp học</label>
                    <select
                      className="form-control"
                      id="input-select"
                      onChange={this.onChangeSelectStadium.bind(this)}
                      value={id}
                    >
                      <option value="all">Tất cả lớp học</option>
                      {state.classList.map((value, index) => {
                        return (
                          <option key={index} value={value._id}>
                            {" "}
                            {value.description}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="input-select">Sân bóng</label>
                    <input
                      id="inputText3"
                      type="text"
                      className="form-control"
                      disabled={true}
                      value={state.stadiumInfo}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="input-select">Loại lớp</label>
                    <input
                      id="inputText3"
                      type="text"
                      className="form-control"
                      disabled={true}
                      ref="isAdvanced"
                    />
                  </div>
                  <div className="form-group">
                    <label for="input-select">Tìm kiếm tên</label>
                    <div style={{ display: "flex" }}>
                      <input
                        id="inputText3"
                        type="text"
                        className="form-control"
                        ref="name"
                        value={state.name}
                        onChange={(e) => {
                          this.setState({ name: e.target.value });
                        }}
                        onKeyDown={(event) => {
                          if (event.keyCode === 13) {
                            this.getStudentsList(
                              this.props.match.params.classId,
                              1
                            );
                          }
                        }}
                      />
                      <button
                        className="btn btn-upload"
                        onClick={() => {
                          this.getStudentsList(
                            this.props.match.params.classId,
                            1
                          );
                        }}
                      >
                        Tìm kiếm
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table
                              id="example2"
                              className="table table-striped table-bordered"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th>STT</th>
                                  <th>Tên</th>
                                  <th>Ghi Chú</th>
                                  <th>Tuổi</th>
                                  <th>Tên Lớp</th>
                                  <th>Trạng thái</th>
                                  <th>Chức Năng</th>
                                </tr>
                              </thead>
                              <tbody>
                                {state.studentList.map((st, index) => {
                                  return (
                                    <tr
                                      key={index}
                                      className={
                                        st.isLate ? "warning-payment-late" : ""
                                      }
                                    >
                                      <td>
                                        {(state.currentPage - 1) * 10 +
                                          index +
                                          1}
                                      </td>
                                      <td>{st.studentName}</td>
                                      <td>{st.parentName}</td>
                                      <td>{st.age}</td>
                                      <td>{st.description}</td>
                                      <td
                                        style={{
                                          background: st.isOff ? "yellow" : "",
                                          color: "black",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {st.isOff
                                          ? "Nghỉ Lâu Ngày Chưa Chốt"
                                          : ""}
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-primary margin-right"
                                          data-toggle="modal"
                                          data-target="#modalDetailRollCall"
                                          onClick={() => {
                                            window.location.href = `/admin/chi-tiet/hoc-phi/${st._id}`;
                                          }}
                                        >
                                          Chi tiết
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-warning"
                                          data-toggle="modal"
                                          data-target="#modalDetailRollCall"
                                          onClick={() => {
                                            window.location.href = `/admin/chi-tiet/phu-thu/${st._id}`;
                                          }}
                                        >
                                          Phụ thu
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                    <div className="pagination-highlights">
                      {state.totalPage > 0 && (
                        <PaginationBt4
                          total={state.totalPage}
                          currentPage={state.currentPage}
                          itemsCountPerPage={10}
                          handlePageChange={this.handlePageChange.bind(this)}
                        ></PaginationBt4>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
